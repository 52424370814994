import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import CatchCardDisclosures from "~common/components/bank-linking/CatchCardDisclosures";
import PaymentInstrumentErrorHeaders from "~common/components/bank-linking/connect/headers/payment-instrument/PaymentInstrumentErrorHeaders";
import { PrimaryButton } from "~common/components/controls/buttons";
import {
  setConnectBankVariant,
  setIsCatchPassFlow,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectConnectBankVariant } from "~src/store";

import EditCard from "../EditCard";

type LinkDebitProps = {
  onEdit: (isActive: boolean, hideCancel?: boolean) => void;
  isEditing: boolean;
  onSuccess: () => void;
};

const LinkDebit: React.VFC<LinkDebitProps> = ({
  onEdit,
  isEditing,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const { paymentInstruments, loading } = usePaymentInstruments();
  const connectBankVariant = useSelector(selectConnectBankVariant);
  const hasError =
    connectBankVariant !== "Default" && connectBankVariant !== "ChangeMethod";
  const paymentInstrument = paymentInstruments[0] || null;

  useEffect(() => {
    dispatch(setIsCatchPassFlow(true));

    return () => {
      dispatch(setIsCatchPassFlow(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !paymentInstrument) {
      onEdit(true, true);
    }
  }, [paymentInstrument, loading, onEdit]);

  useEffect(() => {
    if (connectBankVariant === "Success" && paymentInstrument) {
      onSuccess();

      dispatch(
        setConnectBankVariant(paymentInstrument ? "ChangeMethod" : "Default")
      );
    }
  }, [dispatch, paymentInstrument, connectBankVariant, onSuccess]);

  const handleRetry = () => {
    trackEvent("Link Debit Try again Clicked", {
      component: "LinkDebit",
    });

    dispatch(
      setConnectBankVariant(paymentInstrument ? "ChangeMethod" : "Default")
    );
  };

  const handleConfirm = () => {
    trackEvent("Link Debit Continue Clicked");
    onSuccess();
  };

  if (hasError) {
    return (
      <SmallPagePanel bgcolor="tertiary.main">
        <Stack
          spacing={6}
          sx={({ palette }) => ({
            mt: -4,
            ".BankLinking-header": {
              textAlign: "center",
              alignItems: "center",
            },
            ".MuiTypography-root": {
              color: palette.common.white,
            },
          })}
        >
          <PaymentInstrumentErrorHeaders variant={connectBankVariant} />
          <PrimaryButton onClick={handleRetry}>Try again</PrimaryButton>
        </Stack>
      </SmallPagePanel>
    );
  }

  return (
    <SmallPagePanel
      title={hasError ? undefined : "Link your debit card."}
      subtitle={
        hasError ? undefined : (
          <Box>
            The Catch card comes with a <strong>free digital debit card</strong>{" "}
            linked to your existing debit card, so you can earn everywhere.
          </Box>
        )
      }
      bgcolor="tertiary.main"
    >
      {isEditing ? (
        <AdyenCardConnect inline mode="dark" />
      ) : (
        <>
          {paymentInstrument && (
            <Stack
              spacing={{
                zero: 9,
                md: 12,
              }}
            >
              <EditCard onClick={() => onEdit(true)} mode="dark">
                {paymentInstrument?.display_name}
              </EditCard>

              <PrimaryButton onClick={handleConfirm} fullWidth>
                Continue
                <Box sx={visuallyHidden}> to confirm your identity</Box>
              </PrimaryButton>

              <Box
                pt={{
                  zero: 3,
                  md: 0,
                }}
              >
                <CatchCardDisclosures
                  paymentInstrumentName={paymentInstrument.institution_name}
                  paymentInstrumentAccountType={paymentInstrument.account_type}
                />
              </Box>
            </Stack>
          )}
        </>
      )}
    </SmallPagePanel>
  );
};

export default LinkDebit;
