import React from "react";

import { Link, Stack, Typography } from "@mui/material";

type CatchCardDisclosuresProps = {
  paymentInstrumentName?: string;
  paymentInstrumentAccountType?: string;
};

const CatchCardDisclosures: React.VFC<CatchCardDisclosuresProps> = ({
  paymentInstrumentName,
  paymentInstrumentAccountType,
}) => (
  <Stack spacing={5} color="tints.purple.main">
    <Typography variant="bodySmall">
      By selecting &quot;Continue&quot; above, you authorize Catch to initiate
      electronic payments from your {paymentInstrumentName}{" "}
      {paymentInstrumentAccountType === "debit" ? "card" : "account"} for any
      amount up to the total captured on your virtual card on or after{" "}
      {new Date().toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}
    </Typography>

    <Typography variant="bodySmall">
      Authorization can be revoked by reaching out to customer support at{" "}
      <Link
        href="mailto:help@getcatch.com"
        color="inherit"
        sx={{ fontWeight: 500 }}
      >
        help@getcatch.com
      </Link>
      .
    </Typography>

    <Typography variant="bodySmall">
      By continuing, you agree to have Catch place a temporary hold on your card
      for verification purposes. The hold will be released within minutes.
    </Typography>

    <Typography variant="bodySmall">
      Catch is a financial technology company, not an FDIC insured depository
      institution. Banking services provided by Bangor Savings Bank, Member
      FDIC.
    </Typography>

    <Typography variant="bodySmall">
      The Catch Mastercard® Debit Card is issued by Bangor Savings Bank, Member
      FDIC, pursuant to license by Mastercard International Incorporated.
      Mastercard is a registered trademark, and the circle design is a trademark
      of Mastercard International Incorporated. Spend anywhere Mastercard is
      accepted.
    </Typography>

    <Typography variant="bodySmall">
      By providing Catch your email address you consent to our using the email
      address to send you Service-related notices and agree to our{" "}
      <Link
        href="https://www.getcatch.com/e-sign-agreement"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ fontWeight: 500 }}
      >
        Electronic Communication and E-Sign Agreement
      </Link>
      , including any notices required by law, in lieu of communication by
      postal mail.
    </Typography>
  </Stack>
);

export default CatchCardDisclosures;
